import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Container } from 'theme-ui'
import {
    Wrapper,
    Heading,
    Description,
    CallToActionWrapper,
    ContentContainer,
    ItemContainer,
    ItemIndex,
    Content,
    PageContainer
  } from './styles'
import CallToAction from '~/components/CallToAction'

const CTA = ({ section }) => (
  <Wrapper>
    <Container>
      <Heading as="h2" variant="secondaryMedium">
        {section.heading}
      </Heading>
      {section.body && (
        <Description>
          <ReactMarkdown>{section.body.body}</ReactMarkdown>
        </Description>
      )}

      {section?.content && (
        <PageContainer>
          <ContentContainer>
            {section.content.map((contents, index) => (
              <ItemContainer>
                <ItemIndex>{index + 1}</ItemIndex>
                <Content variant="body">
                  {contents.title}
                </Content>
              </ItemContainer>
            ))}
          </ContentContainer>
        </PageContainer>
      )}

      {section.callToAction && (
        <CallToActionWrapper>
          <CallToAction cta={section.callToAction} onClick={section?.callToAction?.onClick} />
        </CallToActionWrapper>
      )}
    </Container>
  </Wrapper>
)

export default CTA

CTA.propTypes = {
  section: PropTypes.shape({
    heading: PropTypes.string,
    body: PropTypes.shape({
      body: PropTypes.string
    }),
    callToAction: PropTypes.shape({})
  })
}
