import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import { Button } from 'theme-ui'

const CallToAction = ({ cta, onClick }) => {
  const type = cta.page ? 'page' : 'url'
  const base =
    cta.page && cta.page.__typename === 'ContentfulProduct' ? '/products' : ''
  const url = cta.page ? `${base}/${cta.page.slug}/` : `${cta.url}`

  return (
    <>
      {type === 'page' && (
        <Button
          as={Link}
          to={url}
          variant={cta.buttonStyle ? cta.buttonStyle.toLowerCase() : 'primary'}
          title={`visit the ${cta?.page?.slug.replace(/-/g, ' ')} page`}
          onClick={onClick}
        >
          {cta.text}
        </Button>
      )}
      {type === 'url' && (
        <Button
          as="a"
          href={url === 'null' ? '#' : url}
          variant={cta.buttonStyle ? cta.buttonStyle.toLowerCase() : 'primary'}
          onClick={onClick}
        >
          {cta.text}
        </Button>
      )}
    </>
  )
}

export default CallToAction

CallToAction.propTypes = {
  cta: PropTypes.shape({
    text: PropTypes.string,
    page: PropTypes.shape({
      __typename: PropTypes.string,
      slug: PropTypes.string
    }),
    url: PropTypes.string,
    buttonStyle: PropTypes.string
  }),
  onClick: PropTypes.func
}
