import React from 'react'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'
import { openConsentManager } from '@segment/consent-manager'
import CTA from '../sections/Program/CTA/'

const CookiesPage = () => {

  const section = {
    heading: 'Manage Cookie Preferences',
    body: {
      body: "Take control of your cookie preferences all in one place."
    },
    callToAction: {
      text: "Update Cookies",
      url: null,
      buttonStyle: 'secondary',
      onClick: openConsentManager
    }
  }

  return (
    <Page>
      <Metadata
        title="Cookie Preferences"
        description="Manage Your Cookies"
        blockSearchIndexing={true}
      />
      <section>
        <CTA section={section} />
      </section>
    </Page>
  )
}

export default CookiesPage

export const query = graphql`
  query CookiesPage($locale: String) {
    home: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      ...PageFragment
    }
  }
`
