import styled from '@emotion/styled'
import { Heading as ThemeHeading, Text } from 'theme-ui'

export const Wrapper = styled.section`
  padding: 48px 0;
  background-color: ${props => props.theme.colors.cream};

  @media screen and (min-width: 992px) {
    padding: 64px 0;
  }
`

export const Heading = styled(ThemeHeading)`
  margin-bottom: 24px;
  text-align: center;
`

export const Description = styled.div`
  max-width: 650px;
  margin: 0 auto 32px;
  text-align: center;
`

export const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: center;

  a {
    width: 250px;
  }
`
export const PageContainer = styled.div`
  display:flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  gap: 65px;
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 3rem;

  &::after {
    content: '';
    position: absolute;
    left: 13px;
    width: 2px;
    height: calc(100% - 24px);
    background-color: ${props => props.theme.colors.perracotta};
    z-index: 1;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    width: auto;
    margin-top: 3rem;

    &::after {
      left: 0;
      right: 0;
      top: 22px;
      height: 2px;
      margin: auto;
      width: calc(100% - 215px);
    }
  }

  @media screen and (min-width: 992px) {
    &::after {
      width: calc(100% - 320px);
    }
  }
`
export const ItemContainer = styled.div`
  display: flex;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    width: 215px;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    width: 320px;
  }
`
export const ItemIndex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.perracotta};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  z-index: 2;
  color: ${p => p.theme.colors.cream};
  margin-right: 2em;

  @media screen and (min-width: 768px) {
    display: block;
    width: 48px;
    height: 48px;
    font-size: 28px;
    font-weight: 400;
    margin: 0;
  }
`
export const Content = styled(Text)`
  display: flex;
  width: 142px;

  @media screen and (min-width: 768px) {
    width: 205px;
    margin-top: 36px;
    text-align: center;
  }
`
